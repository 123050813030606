// routes
import AppRouter from './routes'
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import NotistackProvider from 'components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

export default function App() {

  const { isInitialized } = useAuth()

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          <ScrollToTop />
          {isInitialized ?  <AppRouter /> : <LoadingScreen />}
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

// @ts-ignore
String.prototype.hashCode = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
