// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DevicesIcon from '@mui/icons-material/Devices';
import GroupIcon from '@mui/icons-material/Group';
import baselineChecklistRtl from '@iconify/icons-ic/baseline-checklist-rtl';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import DomainIcon from '@mui/icons-material/Domain';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  bookings: getIcon('ic_booking'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  link: getIcon('link')
};

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Analyse', path: PATH_DASHBOARD.app.root, icon: ICONS.analytics },
      { title: 'Aufträge', path: PATH_DASHBOARD.orders.root, icon: ICONS.bookings },
      { title: 'Planung', path: PATH_DASHBOARD.planning.root, icon: ICONS.kanban },
      { title: 'Produktionserfassung', path: PATH_DASHBOARD.reports.root, icon: ICONS.bookings },
      { title: 'Zeiterfassung', path: PATH_DASHBOARD.times.root, icon: <TimelapseIcon /> },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Mitarbeiter',
        path: PATH_DASHBOARD.user.root,
        icon: <GroupIcon />
      },
      { title: 'Produktions - Tablets', path: PATH_DASHBOARD.devices.root, icon: <DevicesIcon /> },
      { title: 'Arbeitsstation', path: PATH_DASHBOARD.machine.root, icon: <PrecisionManufacturingIcon /> },
      { title: 'Fertigungsschritte', path: PATH_DASHBOARD.tasks.root, icon: <Icon icon={baselineChecklistRtl} height={24} width={24} /> },
      { title: 'Fertigungs - Räume', path: PATH_DASHBOARD.locations.root, icon: <DomainIcon /> },
      { title: 'QR Codes', path: PATH_DASHBOARD.qrcodes.root, icon: <QrCodeIcon /> }
    ]
  }
];

export const basicSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Analyse', path: PATH_DASHBOARD.app.root, icon: ICONS.analytics },
      { title: 'Aufträge', path: PATH_DASHBOARD.orders.root, icon: ICONS.bookings },
      { title: 'Zeiterfassung', path: PATH_DASHBOARD.times.root, icon: <TimelapseIcon /> },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Mitarbeiter',
        path: PATH_DASHBOARD.user.root,
        icon: <GroupIcon />
      },
      { title: 'Produktions - Tablets', path: PATH_DASHBOARD.devices.root, icon: <DevicesIcon /> },
      { title: 'Arbeitsstation', path: PATH_DASHBOARD.machine.root, icon: <PrecisionManufacturingIcon /> },
      { title: 'Fertigungsschritte', path: PATH_DASHBOARD.tasks.root, icon: <Icon icon={baselineChecklistRtl} height={24} width={24} /> },
      { title: 'Fertigungs - Räume', path: PATH_DASHBOARD.locations.root, icon: <DomainIcon /> }
    ]
  }
]
