import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt'
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { Urls } from 'utils/urls';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    token: string | null;
    user: AuthUser;
    headers: any;
  };
  [Types.Login]: {
    token: string;
    user: AuthUser;
    headers: any;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    token: string;
    headers: any;
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  headers: {},
  user: null,
  token: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        headers: action.payload.headers,
        token: action.payload.token,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        headers: action.payload.headers,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        headers: {},
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        headers: action.payload.headers,
        user: action.payload.user,
        token: action.payload.token,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        const headers = {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': accessToken
          }}

        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get(Urls.ACCOUNT, headers);

          const user = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              token: accessToken,
              user: user,
              headers: headers,
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              token: null,
              isAuthenticated: false,
              user: null,
              headers: headers,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            headers: {},
            isAuthenticated: false,
            token: null, 
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    let response = await fetch(Urls.SIGN_IN, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              "user": {
                "email": email,
                "password": password
              }
            }),
            credentials: "same-origin"
          });

    let accessToken = response.headers.get('Authorization')
    let user = await response.json()

    if(response.status === 201 && accessToken) {
      setSession(accessToken);
      dispatch({
        type: Types.Login,
        payload: {
          user,
          headers: { headers: { "Content-Type": "application/json", "accept": "application/json", "Authorization": accessToken } },
          token: accessToken
        }
      });
    } else {
      throw Error("E-Mail oder Passwort ist falsch")
    }
  };

  const loginWithToken = async (token: string, fingerprint: string) => {
    let response = await fetch(Urls.SIGN_IN, {
            method: 'POST',
            headers: {
              'X-FINGERPRINT': fingerprint,
              'x-user-token': token,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            credentials: "same-origin"
          });
  
    let accessToken = response.headers.get('Authorization')
    let user = await response.json()

    if(accessToken) {
      setSession(accessToken);
      dispatch({
        type: Types.Login,
        payload: {
          user,
          headers: { headers: { "Content-Type": "application/json", "accept": "application/json", "Authorization": accessToken } },
          token: accessToken
        }
      });
    } else {
      throw Error("E-Mail oder Passwort ist falsch")
    }
  };

  /**
   * Register to Gray Connect System
   * @param email String
   * @param password String
   * @param firstName String
   * @param lastName String
   * @param company String
   */
  const register = async (email: string, password: string, firstName: string, lastName: string, company: string) => {
    const response = await axios.post(Urls.SIGN_UP, {
      email,
      company,
      password,
      first_name: firstName,
      last_name: lastName
    });

    if(response.status !== 201) {
      throw Error(response.data.message)
    }

    await login(email, password)
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  const headers = { headers: {"Content-Type": "application/json", "Authorization": localStorage.getItem('accessToken') }}

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginWithToken,
        logout,
        register,
        token: null,
        headers,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
