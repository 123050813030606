const SERVER_URL = `${process.env.REACT_APP_WEB_PROTOCOL}://${process.env.REACT_APP_SERVER_URL}/api/v1/`

export const Urls = {
    SERVER_URL: SERVER_URL,
    SIGN_IN: `${SERVER_URL}login`,
    SIGN_UP: `${SERVER_URL}sign_up`,
    ACCOUNT: `${SERVER_URL}account`,
    USERS: `${SERVER_URL}users`,
    UNITS: `${SERVER_URL}units`,
    TASKS: `${SERVER_URL}tasks`,
    TIMES: `${SERVER_URL}times`,
    ORDERS: `${SERVER_URL}orders`,
    MACHINES: `${SERVER_URL}machines`,
    BOOKINGS: `${SERVER_URL}bookings`,
    LOCATIONS: `${SERVER_URL}locations`,
    STAFF_TIMES: `${SERVER_URL}staff_times`,
    ORDERS_TASKS: `${SERVER_URL}orders_tasks`,
    ORDER_REPORTS: `${SERVER_URL}order_reports`,
    MOBILE_DEVICES: `${SERVER_URL}mobile_devices`,
    RESOURCE_COSTS: `${SERVER_URL}resource_costs`,
    REGISTERED_DEVICES: `${SERVER_URL}registered_devices`
}