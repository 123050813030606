import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        { path: '', element: <Analytics /> },
        { path: 'times', element: <Times /> },
        { path: 'locations', element: <Locations /> },
        { path: 'reports', element: <Reports /> },
        { path: 'plan', element: <Planning /> },
        { path: 'settings', element: <Settings /> },
        { path: 'orders', element: <Orders /> },
        { path: 'orders', 
        children: [
          { path: 'new', element: <OrderNew />},
          { path: ':id/edit', element: <OrderEdit />},
          { path: ":id", element: <Order /> },
          { path: ":id/statistic", element: <OrderStatistic /> },
          { path: ":id/settings", element: null },
          { path: ":id/history", element: null }
        ]},
        { path: 'tasks', element: <Tasks /> },
        { path: 'tasks', children: [
          { path: ":id", element: <NotFound /> },
        ]},
        { path: 'devices', element: <Devices /> },
        { path: 'devices', children: [
          { path: 'register', element: <RegisterDevice /> }
        ] },
        { path: 'users', element: <Users /> },
        { path: 'users', children: [
          { path: ":id", element: <User /> }
        ] },
        { path: 'machines', element: <Machines /> },
        { path: 'qrcodes', element: <QRCodes /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

//App
const Analytics = Loadable(lazy(() => import("../pages/Analyse")));
const Orders = Loadable(lazy(() => import("../pages/orders")) );
const Order = Loadable(lazy(() => import("../pages/order")));
const OrderStatistic = Loadable(lazy(() => import('../pages/order/Statistic')))
const OrderEdit = Loadable(lazy(() => import('../pages/order/Edit')))
const OrderNew = Loadable(lazy(() => import('../pages/order/New')))

const Machines = Loadable(lazy(() => import("../pages/machines")));
const Users = Loadable(lazy(() => import("../pages/users")));
const User = Loadable(lazy(() => import("../pages/user")));
const Devices = Loadable(lazy(() => import("../pages/devices")));
const RegisterDevice = Loadable(lazy(() => import("../pages/devices/Register")))
const Tasks = Loadable(lazy(() => import('../pages/tasks')))

// Zeiterfassung
const Times = Loadable(lazy(() => import('../pages/times')));

// Locations
const Locations = Loadable(lazy(() => import('../pages/locations')))

// Planning
const Planning  = Loadable(lazy(() => import('../pages/planning')))

// Reports
const Reports = Loadable(lazy(() => import('../pages/reports')))

// Settings
const Settings  = Loadable(lazy(() => import('../pages/settings')))

// QRCodes
const QRCodes   = Loadable(lazy(() => import('../pages/qrcodes')))